import xhr from '../xhr/xhr'

const headers = {}
const routePrefix = 'projects'
const route = 'sharing'
const valuations = 'valuations'

const postSharing = (guests = []) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}`
    xhr.Post(url, guests, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const postSharingCheck = (guests = []) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}/check`
    xhr.Post(url, guests, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const putSharing = anonymousKey => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}`
    const params = {
      anonymousKey,
    }
    xhr.Put(url, params, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const deleteSharing = SharedToken => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}/${SharedToken}`
    xhr.Delete(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const getSharing = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}`

    xhr.Get(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const getSharingValuations = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}/${valuations}`

    xhr.Get(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const projects = {
  postSharing,
  postSharingCheck,
  putSharing,
  getSharing,
  deleteSharing,
  getSharingValuations,
}

export default projects
