import xhr from '../xhr/xhr'
import text from './../text'

const headers = {}
const routePrefix = 'listings'
const property = 'valuation'
const propertyComments = 'comments'
const propertyViewed = 'getLastViewedFromIds'
const propertyPublished = 'published'
const propertyIds = 'ids'

const getListing = () => (
  new Promise((resolve, reject) => {
    const url = routePrefix
    xhr.Get(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const postListing = (listingId, publicationId, subscribeAlert = true) => (
  new Promise((resolve, reject) => {
    const url = routePrefix
    const params = {
      listingId,
      publicationId,
      subscribeAlert,
    }
    xhr.PostListing(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const deleteListing = listingId => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${listingId}`
    const params = false
    xhr.Delete(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const patchValuation = (listingId, value) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${listingId}`
    const params = {
      property,
      value,
    }
    xhr.Patch(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const getListingsIds = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${propertyIds}`
    xhr.Get(url, false, headers, true)
    .then((data) => {
      resolve(data.listingIds)
    }, (err) => {
      reject(err)
    })
  })
)

const getListingsComments = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${propertyComments}`
    xhr.Get(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const addListingComment = (listingId, comment, publicationId, subscribeAlert = true) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${listingId}/${propertyComments}`
    const params = {
      publicationId,
      subscribeAlert,
      comment,
    }
    xhr.Post(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)


const deleteListingComment = (listingId, commentId) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${listingId}/${propertyComments}/${commentId}`
    const params = false
    xhr.Delete(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const getViewedListings = () => (
  new Promise((resolve, reject) => {
    const nbd = window.nbdStorage
    if (nbd) {
      nbd.getItem('viewedlsts').then(async (listings) => {
        if (listings.length > 0) {
          const url = `${routePrefix}/${propertyViewed}`
          await xhr.Post(url, listings, headers, true).then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
    } else {
      console.error(`${text.LOGS}nbd n'est pas chargé`)
      reject()
    }
  })
)

const getPublishedListings = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${propertyPublished}`
    xhr.Get(url, false, headers, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const listing = {
  getListing,
  getListingsIds,
  postListing,
  deleteListing,
  patchValuation,
  getListingsComments,
  addListingComment,
  deleteListingComment,
  getViewedListings,
  getPublishedListings,
}

export default listing
