import { authentication } from '@SeLoger/authentication'

const apiUrl = Config ? Config.kitWS : ''

function errorManager(req, resolve, reject) {
  const obj = JSON.parse(req.response)
  const errorCode = obj.d.ErrorCode
  if (errorCode === 0) {
    const data = obj.d.Data
    resolve(data)
  } else {
    const error = {
      errorCode,
      message: obj.d.Messages[0].Message,
    }
    reject(error)
  }
}

function buildRequestPostParams(params = {}) {
  const requestUrl = {
    request: params,
  }
  return JSON.stringify(requestUrl)
}

function tryGetDaTa(params) {
  const connected = Object.prototype.hasOwnProperty.call(params, 'Token')
  const token = authentication.getToken()
  if (connected && !token) {
    throw new Error('l\'utilisateur n\'est pas connecté')
  } else {
    return token
  }
}

function Post(url, params) {
  return new Promise((resolve, reject) => {
    try {
      tryGetDaTa(params)
      const req = new XMLHttpRequest()

      const requestUrl = buildRequestPostParams(params)

      const completeUrl = `${apiUrl}${url}`

      req.open('POST', completeUrl, true)
      req.setRequestHeader('Content-type', 'application/json; charset=utf-8')

      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
          errorManager(req, resolve, reject)
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(requestUrl)
    } catch (error) {
      reject(error)
    }
  })
}

function buildRequestGetParams(params, url) {
  let requestUrl = ''
  if (params) {
    requestUrl = `?request=${JSON.stringify(params)}`
  } else {
    console.warn(`no params for ${url} WS`)
  }
  return requestUrl
}

function Get(url, params) {
  return new Promise((resolve, reject) => {
    try {
      tryGetDaTa(params)
      const req = new XMLHttpRequest()

      const requestUrl = buildRequestGetParams(params, url)

      const completeUrl = `${apiUrl}${url}${requestUrl}`

      req.open('GET', completeUrl)

      req.onload = () => {
        if (req.status === 200) {
          errorManager(req, resolve, reject)
        } else {
          reject(new Error(req.statusText))
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send()
    } catch (error) {
      reject(error)
    }
  })
}

const xhrOld = {
  Post,
  buildRequestPostParams,
  Get,
  buildRequestGetParams,
  errorManager,
}

export default xhrOld
