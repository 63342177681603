import { nbdStorage } from '@SeLoger/NBD'
import xhr from '../xhr/xhr'

const getSuggestListings = async (dataId) => {
  try {
    const exclstids = await nbdStorage.getItem('exclstids')
    const completeUrl = `notifications/suggestlistings/${dataId}`
    return await xhr.Post(completeUrl, exclstids, false)
  } catch (e) {
    return undefined
  }
}

const notification = {
  getSuggestListings,
}
export default notification
