import localStorageSafe from '@SeLoger/authentication'
import xhr from '../xhr/xhr'
import text from './../text'

const routePrefix = 'users'
const headers = {}

const getMail = () => (
  localStorageSafe.getItem('_slpm_uid')
)

const updateUserData = (Key, Value) => (
  new Promise((resolve, reject) => {
    const url = routePrefix
    const params = {
      Key,
      Value,
    }
    xhr.Patch(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)


const openEPShutter = (params = {}) => {
  const { origin, view } = params
  if (typeof origin !== 'string' || !origin) { console.warn(`${text.LOGS}pas d'origine indiquée pour l'ouverture de l'espace perso`) }
  if (typeof view !== 'string' || !view) { console.warn(`${text.LOGS}pas de vue indiquée pour l'ouverture de l'espace perso`) }

  const ep = window.EspacePerso
  if (ep) {
    const apiEP = ep.DevApi
    if (apiEP) {
      const openShutter = apiEP.openEPShutter
      if (typeof openShutter === 'function') {
        openShutter(params)
      } else {
        console.error(`${text.LOGS}la fonction d'ouverture de l'espace perso n'est pas définie`)
      }
    } else {
      console.error(`${text.LOGS}les méthodes de l'espace perso ne sont pas chargées`)
    }
  } else {
    console.error(`${text.LOGS}l'espace perso n'est pas chargé`)
  }
}

const postFeedback = ({ rating, comment }) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/feedback`
    const params = {
      featureId: 'MySpace',
      rating,
      comment,
      userAgent: window.navigator.userAgent,
    }
    xhr.Post(url, params, headers, true).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)
const user = {
  getMail,
  updateUserData,
  openEPShutter,
  postFeedback,
}

export default user
