import xhr from '../xhr/xhr'

const headers = {}
const routePrefix = 'accounts'
const route = 'socialnetwork/signin'
const routeToStandard = 'socialnetwork/updateToStandard'

const signin = (type, token) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${route}`
    const params = {
      type,
      token,
    }
    xhr.Post(url, params, headers).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const updateAccountToStandard = (token, email, newPassword) => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/${routeToStandard}`
    const params = {
      email,
      newPassword,
    }
    xhr.Post(url, params, headers, true, token).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const socialNetwork = {
  signin,
  updateAccountToStandard,
}

export default socialNetwork
