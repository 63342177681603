import xhr from '../xhr/xhr'

const headers = {}
const routePrefix = 'accounts'

const postCheckSharing = anonymousKey => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/checkSharing`
    const params = {
      anonymousKey,
    }
    xhr.Post(url, params, headers, false).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const postCheckType = email => (
  new Promise((resolve, reject) => {
    const isConnectedWS = false
    const url = `${routePrefix}/checkType`
    const params = {
      email,
    }
    xhr.Post(url, params, headers, isConnectedWS).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const account = {
  postCheckSharing,
  postCheckType,
}

export default account
