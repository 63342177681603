import xhr from '../xhr/xhr'
import CONSTANTS from './../constants'

const routePrefix = 'searches'

const getSearches = () => (
  new Promise((resolve, reject) => {
    const url = routePrefix
    const header = false
    xhr.Get(url, false, header, true).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const postAlerts = (params = {}) => (
  new Promise((resolve, reject) => {
    const defaultParams = {
      QueryString: '',
      Label: '',
      TypeEngineId: 1,
      FrequencyId: CONSTANTS.ALERTFREQUENCY.REALTIME,
      BoostAlert: true,
      SendConfirmationEmail: false,
    }
    const newParams = Object.assign({}, defaultParams, params)
    const header = false
    const connected = true
    const completeUrl = `${routePrefix}/alerts`
    xhr.Post(completeUrl, newParams, header, connected).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const postAlertsNC = async (params = {}) => (
  new Promise((resolve, reject) => {
    const defaultParams = {
      QueryString: '',
      Email: '',
      SubscriptionModeId: 0,
      TypeEngineId: 1,
      FrequencyId: CONSTANTS.ALERTFREQUENCY.REALTIME,
      BoostAlert: true,
      SendConfirmationEmail: false,
    }
    const newParams = Object.assign({}, defaultParams, params)
    const header = false
    const completeUrl = `${routePrefix}/alertsNC`
    xhr.Post(completeUrl, newParams, header).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
)

const getSearchesListings = () => (
  new Promise((resolve, reject) => {
    const url = `${routePrefix}/listings`
    const header = false
    const connected = true
    const params = false
    xhr.Get(url, params, header, connected).then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
)

const searches = {
  getSearches,
  postAlerts,
  postAlertsNC,
  getSearchesListings,
}
export default searches
