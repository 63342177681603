import xhr from '../xhr/xhr'

const anonymousKeyId = 'x-anonymous-key'
const headers = {}
let anonymousKey

function getUrlParams() {
  const search = window.location.search.substr(1)
  if (search) {
    // Récuperation des paramètres de l'url desabonnement
    const urlObj = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
    const priceAlertkey = 'slprix'
    const newsAlertKey = 'slali'
    const isAlert = (urlObj.abo && (urlObj.abo === priceAlertkey || urlObj.abo === newsAlertKey))

    // construction des paramètres de retour par defaut
    const output = {
      email: (urlObj.email) ? urlObj.email : 'unknown',
      key: (urlObj.key) ? urlObj.key : '',
      abo: (urlObj.abo) ? urlObj.abo : '',
      id: (isAlert && urlObj.id) ? urlObj.id : 0,
    }

    // validation de l'existence des paramètres Alerteimmo et indentifieur pour retrocompatibilité
    if (urlObj.idalerteimmo && urlObj.identifieur) {
      output.key = urlObj.identifieur
      output.abo = newsAlertKey
      output.id = urlObj.idalerteimmo
    }

    // validation des paramètres de retour
    if (!output.email && !output.abo && !output.key) {
      return ''
    }

    // retrocompatibilité: on valide que l'id de l'alerteimmo, l'id de l'alerte prix peut être 0
    if (output.abo === newsAlertKey && (!output.id)) {
      return ''
    }

    return {
      subscriptionCode: output.abo,
      email: output.email,
      key: output.key,
      id: output.id,
    }
  }
  return ''
}

function pageLoad() {
  return new Promise((resolve, reject) => {
    const params = getUrlParams()
    if (params) {
      const urlParams = `${params.subscriptionCode}/${params.email}/${params.key}/${params.id}`
      const url = `subscriptions/pageLoad/${urlParams}`
      xhr.Get(url).then((data) => {
        anonymousKey = data.anonymousKey
        const fullData = {
          email: data.email,
          mode: params.subscriptionCode,
          key: params.key,
          focus: params.id,
        }
        resolve(fullData)
      })
      .catch((err) => {
        reject(err)
      })
    } else {
      reject({ message: 'Pas d\'identification possible' })
    }
  })
}

function others() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/others'
    headers[anonymousKeyId] = anonymousKey
    xhr.Get(url, false, headers).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function othersSubscribe(subscriptionCode) {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/others'

    headers[anonymousKeyId] = anonymousKey
    xhr.Post(url, subscriptionCode, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function unsubscribeOthers(subscriptionCode) {
  return new Promise((resolve, reject) => {
    const url = `subscriptions/others/${subscriptionCode}`
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function realEstateNewsAlerts() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/realestatenewsalerts'
    headers[anonymousKeyId] = anonymousKey
    xhr.Get(url, false, headers).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function deleteAllNewsAlerts() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/realestatenewsalerts'
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function deleteNewsAlert(alertId) {
  return new Promise((resolve, reject) => {
    const url = `subscriptions/realestatenewsalerts/${alertId}`
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function realEstatePriceAlerts() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/realestatepricealerts'
    headers[anonymousKeyId] = anonymousKey
    xhr.Get(url, false, headers).then((data) => {
      resolve(data)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function deleteAllPriceAlerts() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/realestatepricealerts'
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function deletePriceAlert(alertId) {
  return new Promise((resolve, reject) => {
    const url = `subscriptions/realestatepricealerts/${alertId}`
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function changeFrequency(alertId, frequencyId) {
  return new Promise((resolve, reject) => {
    const url = `subscriptions/realestatenewsalerts/${alertId}`
    headers[anonymousKeyId] = anonymousKey
    xhr.Put(url, frequencyId, headers).then(() => {
      resolve()
    })
      .catch((err) => {
        reject(err)
      })
  })
}

function deleteAll() {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions'
    headers[anonymousKeyId] = anonymousKey
    xhr.Delete(url, false, headers).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

function subscribeContact(params = {}) {
  return new Promise((resolve, reject) => {
    const url = 'subscriptions/others/contactMe'

    const defaultParams = {
      Email: '',
      QueryString: '',
      Name: '',
      PhoneNumber: '',
      PostalCode: '',
      Message: '',
      Ip: '',
      SubscribeNewsletter: false,
      SubscribePartners: false,
    }

    const newParams = Object.assign({}, defaultParams, params)
    const header = false
    const connected = false

    xhr.Post(url, newParams, header, connected).then(() => {
      resolve()
    })
    .catch((err) => {
      reject(err)
    })
  })
}

const subscription = {
  pageLoad,
  others,
  othersSubscribe,
  unsubscribeOthers,
  realEstateNewsAlerts,
  deleteAllNewsAlerts,
  deleteNewsAlert,
  realEstatePriceAlerts,
  deleteAllPriceAlerts,
  deletePriceAlert,
  changeFrequency,
  deleteAll,
  subscribeContact,
}

export default subscription
