import { authentication } from '@SeLoger/authentication'

const apiUrl = Config ? Config.kitAPI : ''

const API_KEY_NAME = 'Authorization'

function succesManager(req, resolve) {
  if (req.response) {
    const data = JSON.parse(req.response)
    resolve(data)
  } else {
    console.error('response WS is undefined')
  }
}

function buildRequest({ url, params, header, token, httpMethod }) {
  const req = new XMLHttpRequest()

  const completeUrl = `${apiUrl}${url}`
  const paramsStringied = JSON.stringify(params)

  req.open(httpMethod, completeUrl, true)
  req.setRequestHeader('Content-type', 'application/json; charset=utf-8')

  if (token) {
    req.setRequestHeader(API_KEY_NAME, token)
  }

  if (header) {
    Object.keys(header).forEach((key) => {
      req.setRequestHeader(key, header[key])
    })
  }

  return { req, paramsStringied }
}

function tryGetDaTa(connected) {
  const token = authentication.getToken()
  if (connected && !token) {
    throw new Error('l\'utilisateur n\'est pas connecté')
  } else {
    return token
  }
}

function Patch(url, params, header, connected) {
  return new Promise((resolve, reject) => {
    try {
      const token = tryGetDaTa(connected)

      const httpMethod = 'PATCH'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          if (req.response) {
            const data = JSON.parse(req.response)
            resolve(data)
          } else {
            resolve()
          }
        }
      }

      req.onerror = () => {
        throw new Error('Network error')
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

function Put(url, params, header, connected) {
  return new Promise((resolve, reject) => {
    try {
      const token = tryGetDaTa(connected)
      const httpMethod = 'PUT'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          if (req.response) {
            const data = JSON.parse(req.response)
            resolve(data)
          } else {
            resolve()
          }
        } else {
          reject(req)
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

function Post(url, params, header, connected, tokenAuth = '') {
  return new Promise((resolve, reject) => {
    try {
      const token = tokenAuth || tryGetDaTa(connected)
      const httpMethod = 'POST'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          succesManager(req, resolve)
        } else if (req.status === 204) {
          resolve()
        } else {
          reject(req.errors ? req.errors[0].message : req.response)
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

function PostListing(url, params, header, connected, tokenAuth = '') {
  return new Promise((resolve, reject) => {
    try {
      const token = tokenAuth || tryGetDaTa(connected)
      const httpMethod = 'POST'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          succesManager(req, resolve)
        } else if (req.status === 204 || req.status === 409) {
          resolve()
        } else {
          reject(req.errors ? req.errors[0].message : req.response)
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

function Get(url, params, header, connected) {
  return new Promise((resolve, reject) => {
    try {
      const token = tryGetDaTa(connected)
      const httpMethod = 'GET'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          succesManager(req, resolve)
        } else {
          reject(new Error(req.errors ? req.errors[0].message : req.response))
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

function Delete(url, params, header, connected) {
  return new Promise((resolve, reject) => {
    try {
      const token = tryGetDaTa(connected)
      const httpMethod = 'DELETE'
      const { req, paramsStringied } = buildRequest({ url, params, header, token, httpMethod })

      req.onload = () => {
        if (req.status === 200) {
          resolve()
        }
      }

      req.onerror = () => {
        reject(new Error('Network error'))
      }

      req.send(paramsStringied)
    } catch (error) {
      reject(error)
    }
  })
}

const xhr = {
  Put,
  Post,
  Get,
  Delete,
  succesManager,
  Patch,
  PostListing
}

export default xhr
